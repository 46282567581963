* {
  box-sizing: border-box;
  font-family: monospace;
  font-size: x-large;
}

#code-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

#soundboard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;
  padding: 20px;

  height: 100vh;
}

.sound-button {
  padding: 10px 20px;
  cursor: pointer;

  flex-grow: 1;
  /* height: 200px; */
}

.display-none {
  display: none !important;
}
