* {
  box-sizing: border-box;
  font-family: monospace;
  font-size: x-large;
}

#code-entry {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  display: flex;
}

#soundboard {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 100vh;
  padding: 20px;
  display: flex;
}

.sound-button {
  cursor: pointer;
  flex-grow: 1;
  padding: 10px 20px;
}

.display-none {
  display: none !important;
}

/*# sourceMappingURL=index.b9da328c.css.map */
